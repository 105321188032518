import React, { useState, useEffect } from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Input } from "@chakra-ui/react";
import axios from "axios";

const CaptureStep1 = ({ item, organisation, warehouse, supplier, initialQty, destinationWarehouse,new_supplier_reference_number, transferData, setTransferData, totals, setTotals }) => {
  const [metadata, setMetadata] = useState([]);

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/stock/get-stock-metadata`, {
          organisation,
          warehouse,
          supplier,
          articlecode: item.articlecode,
        });
        const fetchedData = response.data.data || [];
        setMetadata(fetchedData);
        if (transferData.length === 0) {
          setTransferData(
            fetchedData.map((row) => ({
              ...row,
              transfered_article_soh: 0,
              new_warehousecode: destinationWarehouse || row.new_warehousecode || "",
              new_supplier_reference_number:new_supplier_reference_number||row.new_supplier_reference_number||""
            }))
          );
        }
      } catch (error) {
        console.error("Failed to fetch metadata:", error);
      }
    };

    fetchMetadata();
  }, [organisation, warehouse, supplier, item.articlecode]);

  const handleTransferSohChange = (index, value) => {
    const newData = transferData.map((row, i) => {
      if (i === index) {
        const transferedValue = Math.min(parseInt(value, 10) || 0, row.article_soh);
        return { ...row, transfered_article_soh: transferedValue };
      }
      return row;
    });
    setTransferData(newData);
    setTotals(newData.reduce((sum, row) => sum + (row.transfered_article_soh || 0), 0));
  };

  return (
    <Box>
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            <Th>Supplier Ref</Th>
            <Th>Best Before Date</Th>
            <Th>Article SOH</Th>
            <Th>Transfer Quantity</Th>
          </Tr>
        </Thead>
        <Tbody>
          {metadata.map((row, index) => (
            <Tr key={row.id}>
              <Td>{row.supplier_reference_number}</Td>
              <Td>{row.article_bb_date}</Td>
              <Td>{row.article_soh}</Td>
              <Td>
                <Input type="number" value={transferData[index]?.transfered_article_soh || ""} onChange={(e) => handleTransferSohChange(index, e.target.value)} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default CaptureStep1;
