import React, { useState, useEffect } from "react";
import { Box, Button, Flex, Heading, Spinner, Table, Thead, Tbody, Tr, Td, Th, IconButton, Input, useToast } from "@chakra-ui/react";
import { FaEye, FaEdit, FaPrint } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Capture from "./Capture";
import ViewItem from "./ViewItem";
import axios from "axios";

import Filters from "./Filters";

function Dispatched({ organisation, warehouse, supplier, distributor }) {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  //add row
  const [newRow, setNewRow] = useState(null);
  const [showFirstTable, setShowFirstTable] = useState(false);
  //pagination
  const [page, setPage] = useState(0);
  const [limit] = useState(50);
  const [totalCount, setTotalCount] = useState(0);

  //filters
  const [filters, setFilters] = useState([]);
  const [distributorcodes, setDistributorcodes] = useState([]);
  const [invoice_numbers, setInvoice_numbers] = useState([]);
  const [po_numbers, setPo_numbers] = useState([]);
  const [store_codes, setStore_codes] = useState([]);
  const [store_descriptions, setStore_descriptions] = useState([]);
  const [order_dates, setOrder_dates] = useState([]);
  const [delivery_dates, setDelivery_dates] = useState([]);
  const [created_dates, setCreated_dates] = useState([]);

  const toast = useToast();

  const fetchDispatches = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/stock/get-dispatched`, { organisation, warehouse, supplier, distributor, filters, limit, offset: page * limit }); //filters
      //console.log(response.data.data.dispatched);
      setTableData(response.data.data.dispatched);
      setDistributorcodes(response.data.data.distributorcodes);
      setTotalCount(response.data.data.totalCount);
      setInvoice_numbers(response.data.data.invoice_numbers);
      setPo_numbers(response.data.data.po_numbers);
      setStore_codes(response.data.data.store_codes);
      setStore_descriptions(response.data.data.store_descriptions);
      setOrder_dates(response.data.data.order_dates);
      setDelivery_dates(response.data.data.delivery_dates);
      setCreated_dates(response.data.data.created_dates);
      setLoading(false);
    } catch (error) {
      toast({
        title: "Failed to load orders",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDispatches();
  }, [warehouse, filters]); //filters

  const handleFilterSubmit = (selectedFilters) => {
    setFilters(selectedFilters);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage * limit < totalCount) {
      // Check if the new page is within the range
      setPage(newPage);
      fetchDispatches();
    }
  };

  const handleOpenView = async (item, index) => {
    // console.log({item})
    setLoading(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/stock/view-stock-dispatch`, { organisationcode: organisation, invoice_number: item.invoice_number, po_number: item.po_number });
      const transaction_items = res.data.transaction_items;
      console.log(transaction_items);

      setSelectedItems(transaction_items);
      setIsPopupOpen(true);

      setLoading(false);
    } catch (error) {
      console.log({ error });
      toast({
        title: "Failed to load stock transfer",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const handleCloseView = () => {
    setIsPopupOpen(false);
    setSelectedItem(null);
    setSelectedIndex(null);
  };

  const click_printItem = (item, index) => {};

  if (loading) return <Spinner />;

  return (
    <>
      <Box display="flex" justifyContent="flex-end" gap={4} position="absolute" right="15px" top="75px">
        <Filters onFilterSubmit={handleFilterSubmit} filters={filters} distributorcodes={distributorcodes} invoice_numbers={invoice_numbers} po_numbers={po_numbers} store_codes={store_codes} store_descriptions={store_descriptions} order_dates={order_dates} delivery_dates={delivery_dates} created_dates={created_dates} />
        {/* <Button  colorScheme="customRed">
          Add
        </Button> */}
      </Box>

      <Box overflowY="auto" maxHeight="500px" p={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid" width="100%">
          <Thead
            position="sticky"
            top="0"
            bg="gray.100" // You can choose a different color if desired
            zIndex="1"
          >
            <Tr>
              <Th>View</Th>
              <Th>Print</Th>
              <Th>Type</Th>
              <Th>Delivery</Th>
              <Th>Store Code</Th>
              <Th>Store Name</Th>
              <Th>PO Number</Th>
              <Th>Invoice Number</Th>
              <Th>Invoiced Cases</Th>
              <Th>Total Cases</Th>
              <Th>Cost Excl</Th>
            </Tr>
          </Thead>
          <Tbody>
            {tableData.map((item, index) => (
              <Tr key={item.id}>
                <Td>
                  <IconButton variant="outline" colorScheme="customRed" size="sm" icon={<FaEye />} aria-label="View" onClick={() => handleOpenView(item, index)} />
                </Td>
                <Td>
                  <IconButton variant="outline" colorScheme="green" icon={<FaPrint />} size="sm" aria-label="Edit" onClick={() => click_printItem(item, index)} />
                </Td>
                <Td>{item.debriefed ? "debrief" : "invoice"}</Td>
                <Td>{item.debriefed ? item.debrief_status : ""}</Td>

                <Td>{item.store_code}</Td>
                <Td>{item.store_description}</Td>
                <Td>{item.po_number}</Td>

                <Td>{item.invoice_number}</Td>

                <Td>{item.debriefed ? item.cases_delivered : item.quantity}</Td>

                <Td>{item.quantity}</Td>

                <Td>{item.cost_excl}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Flex justifyContent="space-between" mt={4}>
        <Button
          onClick={() => handlePageChange(page - 1)}
          isDisabled={page === 0} // Disable Previous button if on the first page
        >
          Previous
        </Button>
        <Button
          onClick={() => handlePageChange(page + 1)}
          isDisabled={(page + 1) * limit >= totalCount} // Disable Next button if there are no more invoices
        >
          Next
        </Button>
      </Flex>

      {selectedItems && (
        <ViewItem
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
            setSelectedItems(null);
          }}
          items={selectedItems}
        />
      )}
    </>
  );
}

export default Dispatched;
