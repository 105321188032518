import React, { useState } from "react";
//import axios from "axios";
import { Box, useTheme, Spinner, Button, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, VStack, Checkbox } from "@chakra-ui/react";
import axios from "axios";
import { useSelection } from "../../wrappers/SelectionContext";

import SubNav from "../../components/SubNav";
import Capture from "./components/Capture";
import Summary from "./components/Summary";

import { FaPrint } from "react-icons/fa";

const Orders = () => {
  const theme = useTheme();
  const { selectedOrganisation, selectedWarehouse, selectedSupplier, selectedDistributor, selectedRetailer } = useSelection();
  const [isUploading1, setIsUploading1] = useState(false);
  const [isUploading2, setIsUploading2] = useState(false);
  const [isUploading3, setIsUploading3] = useState(false);
  const [isUploading4, setIsUploading4] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [includeSupplier, setIncludeSupplier] = useState(false);
  const [includeWarehouse, setIncludeWarehouse] = useState(false);

  const [selectedDownloadType, setSelectedDownloadType] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const tabData = [
    {
      label: "Capture",
      component: <Capture organisation={selectedOrganisation} retailer={selectedRetailer} warehouse={selectedWarehouse} supplier={selectedSupplier} distributor={selectedDistributor} />,
    },
    {
      label: "Summary",
      component: <Summary organisation={selectedOrganisation} warehouse={selectedWarehouse} supplier={selectedSupplier} retailer={""} distributor={selectedDistributor} />,
    },
  ];

  const download__item_history = () => {
    setSelectedDownloadType("item_history");
    setIsModalOpen(true);
  };

  const download__stock_summary = () => {
    setSelectedDownloadType("stock_summary");
    setIsModalOpen(true);
  };

  const download__faulty_stock = () => {
    setSelectedDownloadType("faulty_stock");
    setIsModalOpen(true);
  };

  const download__lost_sales = () => {
    setSelectedDownloadType("lost_sales");
    setIsModalOpen(true);
  };

  const handleDownload = async () => {
    setIsUploading(true);
    try {
      const body = { organisation: selectedOrganisation };

      if (includeSupplier) {
        body.supplier = selectedSupplier;
      }
      if (includeWarehouse) {
        body.warehouse = selectedWarehouse;
      }

      let response;
      let filename;

      switch (selectedDownloadType) {
        case "item_history":
          response = await axios.post(`${process.env.REACT_APP_API_URL}/stock/download-item-history`, body, {
            responseType: "blob",
          });
          filename = `item_history.csv`;
          break;
        case "stock_summary":
          response = await axios.post(`${process.env.REACT_APP_API_URL}/stock/download-stock-summary`, body, {
            responseType: "blob",
          });
          filename = `stock_summary.csv`;
          break;
        case "faulty_stock":
          response = await axios.post(`${process.env.REACT_APP_API_URL}/stock/download-expired-damaged`, body, {
            responseType: "blob",
          });
          filename = `faulty_stock.csv`;
          break;
        case "lost_sales":
          response = await axios.post(`${process.env.REACT_APP_API_URL}/stock/download-item-history`, body, {
            responseType: "blob",
          });
          filename = `lost_sales.csv`;
          break;
        default:
          throw new Error("Invalid download type");
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading:", error);
    } finally {
      setIsUploading(false);
      setIsModalOpen(false);
    }
  };

  return (
    <Box p={4} display="flex" flexDirection="column" height="96vh" overflow={"hidden"}>
      <SubNav mt={12} data={tabData} />
      {/* button group at bottom of page */}
      <Box flexGrow={1} />
      <Flex gap="20px">
        <Button as="span" mt={4} leftIcon={isUploading1 ? <Spinner /> : <FaPrint />} onClick={download__item_history} isLoading={isUploading1} loadingText="Downloading" cursor="pointer" colorScheme="customRed" variant="outline">
          Item History
        </Button>
        <Button as="span" mt={4} leftIcon={isUploading2 ? <Spinner /> : <FaPrint />} onClick={download__stock_summary} isLoading={isUploading2} loadingText="Downloading" cursor="pointer" colorScheme="customRed" variant="outline">
          Stock Summary
        </Button>
        <Button as="span" mt={4} leftIcon={isUploading3 ? <Spinner /> : <FaPrint />} onClick={download__faulty_stock} isLoading={isUploading3} loadingText="Downloading" cursor="pointer" colorScheme="customRed" variant="outline">
          Expired/Damaged Stock
        </Button>
       {/* <Button as="span" mt={4} leftIcon={isUploading4 ? <Spinner /> : <FaPrint />} onClick={download__lost_sales} isLoading={isUploading4} loadingText="Downloading" cursor="pointer" colorScheme="customRed" variant="outline">
          Lost Sales
        </Button>*/}
      </Flex>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="start" spacing={4} mt={4}>
              <Checkbox isChecked={includeSupplier} onChange={(e) => setIncludeSupplier(e.target.checked)}>
                Include Supplier
              </Checkbox>
              <Checkbox isChecked={includeWarehouse} onChange={(e) => setIncludeWarehouse(e.target.checked)}>
                Include Warehouse
              </Checkbox>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleDownload} isLoading={isUploading1} loadingText="Downloading">
              Download
            </Button>
            <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Orders;
