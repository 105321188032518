import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Table, Thead, Tbody, Tr, Th, Td, Box } from "@chakra-ui/react";

const ViewItem = ({ isOpen, onClose, items }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="7xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Item Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4}>
            <Table
              variant="simple"
              size="sm"
              sx={{
                borderCollapse: "collapse",
                "th, td": {
                  padding: "4px",
                  fontSize: "12px",
                },
              }}
            >
              <Thead>
                <Tr>
                  <Th>Invoice Number</Th>
                  <Th>PO Number</Th>
                  <Th>Article Code</Th>
                  <Th>Supplier Article Code</Th>
                  <Th>Retailer Article Code</Th>
                  <Th>Article Cost</Th>
                  <Th>Article Name</Th>
                  <Th>Pack Size</Th>
                  <Th>Qty</Th>
                  <Th>Cost Excl</Th>
                  <Th>Cost VAT</Th>
                  <Th>Cost Incl</Th>
                  <Th>Cases Invoiced</Th>
                  <Th>Cases Delivered</Th>
                  <Th>Cases Returned</Th>
                  <Th>Good Stock</Th>
                  <Th>Bad Stock</Th>
                  <Th>Missing Stock</Th>
                </Tr>
              </Thead>
              <Tbody>
                {items.map((item, index) => (
                  <Tr key={index}>
                    <Td>{item.invoice_number}</Td>
                    <Td>{item.po_number}</Td>
                    <Td>{item.articlecode}</Td>
                    <Td>{item.supplier_articlecode}</Td>
                    <Td>{item.retailer_articlecode}</Td>
                    <Td>{parseFloat(item.article_cost).toFixed(2)}</Td>
                    <Td>{item.article_name}</Td>
                    <Td>{item.article_packsize}</Td>
                    <Td>{item.quantity}</Td>
                    <Td>{parseFloat(item.cost_excl).toFixed(2)}</Td>
                    <Td>{parseFloat(item.cost_vat).toFixed(2)}</Td>
                    <Td>{parseFloat(item.cost_incl).toFixed(2)}</Td>
                    <Td>{item.cases_invoiced}</Td>
                    <Td>{item.cases_delivered}</Td>
                    <Td>{item.cases_returned}</Td>
                    <Td>{item.good_stock}</Td>
                    <Td>{item.bad_stock}</Td>
                    <Td>{item.missing_stock}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ViewItem;
