const formatDecimals = (num, decimals = 3) => {
  const parsedNum = parseFloat(num);
  if (isNaN(parsedNum)) {
    return 0; // Return 0 or any default value if num is not a valid number
  }
  return parseFloat(parsedNum.toFixed(decimals));
};

export const formatDate = (dateInput) => {
  const date = new Date(dateInput);

  const year = date.getUTCFullYear();
  const month = padZero(date.getUTCMonth() + 1); // Months are zero-based
  const day = padZero(date.getUTCDate());

  return `${year}-${month}-${day}`;
};
export const formatDateTime = (dateTimeInput) => {
  const date = new Date(dateTimeInput);

  const year = date.getUTCFullYear();
  const month = padZero(date.getUTCMonth() + 1);
  const day = padZero(date.getUTCDate());
  const hours = padZero(date.getUTCHours());
  const minutes = padZero(date.getUTCMinutes());

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export { formatDecimals };


const padZero = (num) => (num < 10 ? `0${num}` : num.toString());