import React, { useState } from "react";
import { Box, Button, useTheme } from "@chakra-ui/react";

import CaptureStep1 from "./CaptureStep1";
import CaptureStep2 from "./CaptureStep2";
import CaptureStep3 from "./CaptureStep3";
import Breadcrumb from "../../../../../components/Breadcrumb";

const steps = [
  { title: "Order Info", description: "" },
  { title: "Articles", description: "" },
  { title: "Finalize", description: "" },
];

const Capture = ({ invoice, index,organisation, warehouse, supplier, retailer , onSubmit, onCancel }) => {
  const theme = useTheme();
  const [step1Data, setStep1Data] = useState(invoice ? { invoice_number:invoice.invoice_number, po_number: invoice.po_number, order_date: invoice.order_date, 
    delivery_date: invoice.delivery_date, store_code: invoice.store_code, store_description: invoice.store_description } : {});
  const [step2Data, setStep2Data] = useState(invoice ? invoice.transaction_items : []);

  const handleStep1Submit = (data) => {
    setStep1Data(data);
  };

  const handleStep2Submit = (data) => {
    setStep2Data(data);
  };

  const handleNextStep = async (currentStep) => {
    if (currentStep === 0) {
      const result = await CaptureStep1.verify(handleStep1Submit);
      if (!result) return false;
    }
    if (currentStep === 1) {
      const result = await CaptureStep2.verify();
      if (!result) return false;
    }
    return true;
  };
  const handleOrderSubmit =  (order) => {
     onSubmit(order, index);
  };

  const stepContents = [<CaptureStep1 step1Data={step1Data} organisation={organisation} retailer={retailer} onSubmit={handleStep1Submit} />, 
  <CaptureStep2 step1Data={step1Data} step2Data={step2Data} organisation={organisation} retailer={retailer} supplier={supplier} warehouse={warehouse} onSubmit={handleStep2Submit} />, 
  <CaptureStep3 step1Data={step1Data} step2Data={step2Data} onSubmit={handleOrderSubmit} />];

  return (
    <Box>
      <Breadcrumb steps={steps} stepContents={stepContents} onNext={handleNextStep} />
      <Button onClick={onCancel} mt={4} colorScheme="customRed" variant="outline">
        Back to Table
      </Button>
    </Box>
  );
};

export default Capture;
