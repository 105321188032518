import React, { useState } from "react";
import { Box, Button, useToast } from "@chakra-ui/react";
import Breadcrumb from "../../../../../components/Breadcrumb";
import CaptureStep1 from "./CaptureStep1";
import CaptureStep2 from "./CaptureStep2";

const steps = [
  { title: "Select Articles", description: "" },
  { title: "Review & Submit", description: "" },
];

const Capture = ({ item, index, organisation, warehouse, supplier, onSubmit, onCancel }) => {
  const toast=useToast();
  const initialQty = item.transfered_article_soh;
  const destinationWarehouse = item.new_warehousecode;
  const new_supplier_reference_number=item.new_supplier_reference_number;
  const [transferData, setTransferData] = useState([]);
  const [totals, setTotals] = useState(0);

  const handleNext = async (currentStep) => {
    if (currentStep === 0) {
      if (!transferData.length || totals <= 0 || totals !== parseInt(initialQty, 10)) {
        toast({
          title: "Validation Error",
          description: "Transfer data is incomplete or does not match the initial quantity.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    }
    return true;
  };

  const handleStep2Submit = (metadata) => {
    onSubmit(metadata);
  };

  const stepContents = [
    <CaptureStep1
      item={item}
      organisation={organisation}
      warehouse={warehouse}
      supplier={supplier}
      initialQty={initialQty}
      destinationWarehouse={destinationWarehouse}
      new_supplier_reference_number={new_supplier_reference_number}
      transferData={transferData}
      setTransferData={setTransferData}
      totals={totals}
      setTotals={setTotals}
    />,
    <CaptureStep2
      initialQty={initialQty}
      metadata={transferData}
      totals={totals}
      onSubmit={handleStep2Submit}
    />,
  ];

  return (
    <Box>
      <Breadcrumb steps={steps} stepContents={stepContents} onNext={handleNext} />
      <Button onClick={onCancel} mt={4} colorScheme="customRed" variant="outline">
        Cancel
      </Button>
    </Box>
  );
};

export default Capture;
