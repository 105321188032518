import React, { useState } from "react";
import { Box, useTheme, Spinner, Flex } from "@chakra-ui/react";
// import { FaSave, FaPlus } from "react-icons/fa";

import ExpandingButtonGroup from "./capture/ExpandingButtonGroup";
import GRV from "./capture/grv/GRV";
import StockTransfer from "./capture/stockTransfer/StockTransfer";
import Returns from "./capture/returns/Returns";
import Dispatched from "./capture/dispatch/Dispatched";

const Capture = ({ organisation,supplier, warehouse, distributor,retailer }) => {
  const theme = useTheme();
  const [activeButton, setActiveButton] = useState("GRV");

  return (
    <Flex flexDirection={"column"} gap={"4"} >
      <Box >
        <ExpandingButtonGroup onActiveButtonChange={setActiveButton} />
      </Box>
      {activeButton == "GRV" && <GRV organisation={organisation} retailer={retailer} warehouse={warehouse} supplier={supplier} distributor={distributor} />}
      {activeButton == "Stock Transfer" && <StockTransfer organisation={organisation} warehouse={warehouse} supplier={supplier} distributor={distributor} />}
      {activeButton == "Returns" && <Returns organisation={organisation} warehouse={warehouse} supplier={supplier} distributor={distributor} />}
      {activeButton == "Dispatch" && <Dispatched organisation={organisation} warehouse={warehouse} supplier={supplier} distributor={distributor} />}
    </Flex>
  );
};

export default Capture;
