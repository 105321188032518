import React, { useState, useEffect } from "react";
import { Box, Button, Input, Select, Table, Thead, Tbody, Tr, Th, Td, Divider, useTheme, useToast } from "@chakra-ui/react";
import { FaPlus, FaTrash } from "react-icons/fa";
import axios from "axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CaptureStep1 = ({ stockItem, organisation, warehouse, supplier, distributor, retailer, onSubmit, initialArticles }) => {
  const theme = useTheme();
  const toast = useToast();
  const [availableArticles, setAvailableArticles] = useState([]);
  const [articles, setArticles] = useState(initialArticles || []);
  const [totals, setTotals] = useState({
    article_cost_incl: 0,
    article_cost_excl: 0,
    article_vat: 0,
    article_quantity: 0,
  });
  const [newArticle, setNewArticle] = useState({
    supplier_articlecode: "",
    article_desc: "",
    article_packsize: 0,
    article_quantity: 0,
    article_cost: 0,
    article_cost_excl: 0,
    article_cost_incl: 0,
    article_vat: 0,
    article_bb_date: "",
    comment: "",
  });

  useEffect(() => {
    const fetchArticles = async () => {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generic/get-dd-articles`, { supplier, retailer, organisation,warehouse });
      if (response.data.status !== "success") {
        toast({
          title: response.data.title,
          description: response.data.message,
          status: response.data.status,
          duration: 2500,
          isClosable: true,
        });
        return;
      }
      setAvailableArticles(response.data.data.articles);
    };

    fetchArticles();
  }, [stockItem, retailer]);

  useEffect(() => {
    onSubmit(articles, totals);
  }, [articles, totals, onSubmit]);

  useEffect(() => {
    calculateTotals();
  }, [articles]);

  const handleArticleCode = (index, value) => {
    const selectedArticle = availableArticles.find((article) => article.supplier_articlecode === value);
    if (selectedArticle) {
      const article_vat = parseFloat(selectedArticle.article_cost * 0.15).toFixed(2);
      const article_cost_incl = parseFloat(selectedArticle.article_cost * 1.15).toFixed(2);
      const article_cost_excl = parseFloat(selectedArticle.article_cost).toFixed(2);

      const updatedArticles = articles.map((article, i) => {
        if (i === index) {
          return {
            ...article,
            supplier_articlecode: selectedArticle.supplier_articlecode,
            article_desc: selectedArticle.article_desc,
            article_packsize: selectedArticle.article_packsize,
            article_cost: selectedArticle.article_cost,
            article_cost_excl: article_cost_excl,
            article_vat: article_vat,
            article_cost_incl: article_cost_incl,
          };
        }
        return article;
      });

      setArticles(updatedArticles);
    }
  };

  const handleQuantity = (index, value) => {
    const updatedArticles = articles.map((article, i) => {
      if (i === index) {
        const article_quantity = value === "" ? "" : parseInt(value, 10);
        const article_vat = article_quantity === "" ? 0 : parseFloat(article.article_cost * article_quantity * 0.15).toFixed(2);
        const article_cost_incl = article_quantity === "" ? 0 : parseFloat(article.article_cost * article_quantity * 1.15).toFixed(2);
        const article_cost_excl = article_quantity === "" ? 0 : parseFloat(article.article_cost * article_quantity).toFixed(2);
        return {
          ...article,
          article_quantity: article_quantity,
          article_vat: article_vat,
          article_cost_incl: article_cost_incl,
          article_cost_excl: article_cost_excl,
        };
      }
      return article;
    });
    setArticles(updatedArticles);
  };

  const handleArticleCost = (index, value) => {
    const updatedArticles = articles.map((article, i) => {
      if (i === index) {
        const article_cost = value === "" ? "" : parseFloat(value);
        const article_quantity = article.article_quantity;
        const article_vat = article_quantity === "" ? 0 : parseFloat(article_cost * article_quantity * 0.15).toFixed(2);
        const article_cost_incl = article_quantity === "" ? 0 : parseFloat(article_cost * article_quantity * 1.15).toFixed(2);
        const article_cost_excl = article_quantity === "" ? 0 : parseFloat(article_cost * article_quantity).toFixed(2);
        return {
          ...article,
          article_cost: article_cost,
          article_vat: article_vat,
          article_cost_incl: article_cost_incl,
          article_cost_excl: article_cost_excl,
        };
      }
      return article;
    });
    setArticles(updatedArticles);
  };

  const handleArticlesTdChange = (key, value, index) => {
    const updatedArticles = [...articles];
    updatedArticles[index][key] = value;
    setArticles(updatedArticles);
  };

  const handleRemove = (index) => {
    setArticles((currentArticles) => {
      return currentArticles.filter((_, i) => i !== index);
    });
  };

  const addArticle = () => {
    setArticles([...articles, { ...newArticle }]);
    setNewArticle({
      supplier_articlecode: "",
      article_desc: "",
      article_packsize: 0,
      article_quantity: 0,
      article_cost: 0,
      article_cost_excl: 0,
      article_cost_incl: 0,
      article_vat: 0,
      article_bb_date: "",
      comment: "",
    });
  };

  const calculateTotals = () => {
    let tots = {
      article_cost_incl: 0,
      article_cost_excl: 0,
      article_vat: 0,
      article_quantity: 0,
    };
    articles.forEach((article) => {
      tots.article_quantity += parseInt(article.article_quantity) || 0;
      tots.article_cost_excl += parseFloat(article.article_cost_excl) || 0;
      tots.article_vat += parseFloat(article.article_vat) || 0;
      tots.article_cost_incl += parseFloat(article.article_cost_incl) || 0;
    });
    tots.article_vat = parseFloat(tots.article_vat).toFixed(3);
    tots.article_cost_excl = parseFloat(tots.article_cost_excl).toFixed(3);
    tots.article_cost_incl = parseFloat(tots.article_cost_incl).toFixed(3);
    setTotals(tots);
  };

  CaptureStep1.verify = async () => {
    return true;
  };

  return (
    <Box p={4} borderWidth={1} borderRadius="md" bg="white">
      <Box mb={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Supplier Ref Nr</Th>
              <Th>Date</Th>
              <Th>Supplier</Th>
              <Th>Total Cases</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr key={1}>
              <Td>{stockItem.id}</Td>
              <Td>{stockItem.supplierRef}</Td>
              <Td>{stockItem.date}</Td>
              <Td>{stockItem.supplier}</Td>
              <Td>{stockItem.totalCases}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Divider />
      <Box mb={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th>Article Code</Th>
              <Th>Article Name</Th>
              <Th>Pack Size</Th>
              <Th>Quantity</Th>
              <Th>Cost (excl)</Th>
              <Th>VAT</Th>
              <Th>Cost (incl)</Th>
              <Th>BB Date</Th>
              <Th>
                <Button rightIcon={<FaPlus />} onClick={addArticle} ml={2} size="xs" colorScheme="customRed" variant="outline">
                  Add
                </Button>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {articles.map((article, index) => (
              <Tr key={index}>
                <Td>
                  <Select value={article.supplier_articlecode} onChange={(e) => handleArticleCode(index, e.target.value)} placeholder="Select Article">
                    {availableArticles.map((optArticle) => (
                      <option key={optArticle.id} value={optArticle.supplier_articlecode}>
                        {optArticle.supplier_articlecode}
                      </option>
                    ))}
                  </Select>
                </Td>
                <Td>
                  <Input value={article.article_desc} onChange={(e) => handleArticlesTdChange("article_desc", e.target.value, index)} />
                </Td>
                <Td>
                  <Input type="number" value={article.article_packsize} onChange={(e) => handleArticlesTdChange("article_packsize", e.target.value, index)} />
                </Td>
                <Td>
                  <Input type="number" value={article.article_quantity} onChange={(e) => handleQuantity(index, e.target.value)} errorBorderColor="crimson" />
                </Td>
                <Td>
                  <Input type="number" value={article.article_cost_excl} onChange={(e) => handleArticleCost(index, e.target.value)} errorBorderColor="crimson" />
                </Td>
                <Td>
                  <Input type="number" value={article.article_vat} onChange={(e) => handleArticlesTdChange("article_vat", e.target.value, index)} errorBorderColor="crimson" />
                </Td>
                <Td>
                  <Input type="number" value={article.article_cost_incl} onChange={(e) => handleArticlesTdChange("article_cost_incl", e.target.value, index)} />
                </Td>
                <Td>
                  <DatePicker
                    placeholderText="Select BB Date"
                    selected={article.article_bb_date ? new Date(article.article_bb_date) : null}
                    onChange={(date) =>
                      handleArticlesTdChange(
                        "article_bb_date",
                        date
                          ? date.toLocaleDateString("en-CA", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })
                          : "",
                        index
                      )
                    }
                    dateFormat="yyyy/MM/dd"
                    customInput={<Input width="200px" />}
                  />
                </Td>
                <Td>
                  <Button rightIcon={<FaTrash />} onClick={() => handleRemove(index)} ml={2} size="xs" colorScheme="customRed" variant="outline">
                    Remove
                  </Button>
                </Td>
              </Tr>
            ))}
            <Tr borderTop={"2px solid black"}>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td style={{ fontWeight: "bold" }} textAlign="right">
                {totals.article_quantity || ""}
              </Td>
              <Td style={{ fontWeight: "bold" }} textAlign="right">
                {totals.article_cost_excl > 0 ? totals.article_cost_excl : ""}
              </Td>
              <Td style={{ fontWeight: "bold" }} textAlign="right">
                {totals.article_vat > 0 ? totals.article_vat : ""}
              </Td>
              <Td style={{ fontWeight: "bold" }} textAlign="right">
                {totals.article_cost_incl > 0 ? totals.article_cost_incl : ""}
              </Td>
              <Td></Td>
              <Td></Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default CaptureStep1;
