import React from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Button, useToast } from "@chakra-ui/react";
import StockStats from "../StockStats";

const CaptureStep2 = ({ initialQty, metadata = [],  onSubmit }) => {
  const toast = useToast();
  const handleSubmit = () => {
    const totalCalculated = metadata.reduce((sum, row) => sum + (parseInt(row.transfered_article_soh, 10) || 0), 0);
    if (totalCalculated === parseInt(initialQty, 10)) {
      onSubmit(metadata);
    } else {
      toast({
        title: "Error",
        description: "Totals do not match the initial quantity.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <StockStats totalCases={initialQty} totalQuantity={metadata.reduce((sum, row) => sum + (parseInt(row.transfered_article_soh, 10) || 0), 0)} />
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            <Th>Supplier Ref</Th>
            <Th>Best Before Date</Th>
            <Th>Initial SOH</Th>
            <Th>Transfer Quantity</Th>
            <Th>From Warehouse</Th>
            <Th>To Warehouse</Th>
          </Tr>
        </Thead>
        <Tbody>
          {metadata.map((row) => (
            <Tr key={row.id}>
              <Td>{row.supplier_reference_number}</Td>
              <Td>{row.article_bb_date}</Td>
              <Td>{row.article_soh}</Td>
              <Td>{row.transfered_article_soh}</Td>
              <Td>{row.warehousecode}</Td>
              <Td>{row.new_warehousecode}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Button onClick={handleSubmit} colorScheme="customRed" mt={4}>
        Submit
      </Button>
    </Box>
  );
};

export default CaptureStep2;
