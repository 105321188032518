import React, { useState, useEffect } from "react";
import { Box, Button, Spinner, Table, Thead, Tbody, Tr, Td, Th, IconButton, Input, useToast, Flex } from "@chakra-ui/react";
import { FaEye, FaEdit } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ViewItem from "./ViewItem";
import Capture from "./Capture";
import Filters from "./Filters";
import axios from "axios";

function GRV({ organisation, warehouse, supplier, distributor, retailer }) {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  //add row
  const [newRow, setNewRow] = useState(null);
  const [showFirstTable, setShowFirstTable] = useState(false);
  //pagination
  const [page, setPage] = useState(0);
  const [limit] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  //filters
  const [filters, setFilters] = useState([]);
  const [suppliercodes, setSuppliercodes] = useState([]);
  const [distributorcodes, setDistributorcodes] = useState([]);
  const [supplier_reference_numbers, setSupplier_reference_numbers] = useState([]);
  const [date_receiveds, setDate_receiveds] = useState([]);

  const toast = useToast();

  const handleFilterSubmit = (selectedFilters) => {
    setFilters(selectedFilters);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage * limit < totalCount) {
      // Check if the new page is within the range
      setPage(newPage);
      fetchGRV();
    }
  };

  const fetchGRV = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/stock/get-GRV`, { organisation, warehouse, supplier, distributor, filters, limit, offset: page * limit });
      console.log(response.data.data.GRV);
      setTableData(response.data.data.GRV);
      setSuppliercodes(response.data.data.suppliercodes);
      setTotalCount(response.data.data.totalCount);
      setLoading(false);
    } catch (error) {
      toast({
        title: "Failed to load orders",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
    }
  };
  const handleAddButtonClick = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/stock/get-GRV-stock-id`, { supplier });
      const stock_id = response.data.stock_id;

      const newItem = {
        id: stock_id,
        supplierRef: "",
        date: "",
        totalCases: "",
      };
      setNewRow(newItem);
      setShowFirstTable(true);
    } catch (error) {
      console.error("Error retrieving new ID:", error);
    }
  };

  useEffect(() => {
    fetchGRV();
  }, [warehouse, filters]);

  const handleOpenView = async (item, index) => {
    setLoading(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/stock/view-stock-GRV`, { organisationcode: organisation, supplier_reference_number: item.supplier_reference_number });
      const stock_metadata = res.data.stock_metadata;
      console.log(stock_metadata);

      setSelectedItems(stock_metadata);
      setIsPopupOpen(true);

      setLoading(false);
    } catch (error) {
      console.log({ error });
      toast({
        title: "Failed to load stock transfer",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
    }

    // setSelectedItem(item);
    // setSelectedIndex(index);
    // setIsPopupOpen(true);
    //articlecode,warehousecode
  };

  const handleCloseView = () => {
    setIsPopupOpen(false);
    setSelectedItem(null);
    setSelectedIndex(null);
  };

  const handleOpenEdit = (item, index) => {
    setSelectedItem(item);
    setSelectedIndex(index);
    setEditMode(true);
  };

  const handleEditComplete = async (newArticles, newTotals, index) => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/stock/insert-GRV`, {
        stockItem: newRow,
        articleData: newArticles,
        totalsData: newTotals,
        organisation,
        warehouse,
        supplier,
        distributor,
        retailer,
        userid: localStorage.getItem("userid"),
      });
      fetchGRV();
      setLoading(false);
      setEditMode(false);
      setSelectedItem(null);
      setSelectedIndex(null);
      setNewRow(null);
      setShowFirstTable(false);
    } catch (error) {
      toast({
        title: "Failed to load orders",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
    }

    // const updatedItem = {
    //   ...tableData[index],
    //   articles: newArticles,
    //   costExcl: newTotals.article_cost_excl,
    //   costIncl: newTotals.article_cost_incl,
    //   vat: newTotals.vat,
    //   completed: true,
    // };
    // console.log({updatedItem,newRow})
    // const filteredData = tableData.filter((item, i) => i !== index);
    // const newTableData = [...filteredData, updatedItem];
    // setTableData(newTableData);
    // setEditMode(false);
    // setSelectedItem(null);
    // setSelectedIndex(null);
  };

  if (editMode) {
    return <Capture item={selectedItem} index={selectedIndex} organisation={organisation} warehouse={warehouse} supplier={supplier} distributor={distributor} retailer={retailer} onSubmit={handleEditComplete} onCancel={() => setEditMode(false)} />;
  }
  if (loading) return <Spinner />;

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={4} gap={4} position="absolute" right="15px" top="75px">
        <Filters onFilterSubmit={handleFilterSubmit} date_receiveds={date_receiveds} supplier_reference_numbers={supplier_reference_numbers} distributorcodes={distributorcodes} suppliercodes={suppliercodes} />
        <Button onClick={handleAddButtonClick} colorScheme="customRed">
          Add
        </Button>
      </Box>

      <Box overflowY="auto" maxHeight="500px" p={4}>
        {showFirstTable && newRow && (
          <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid" width="100%">
            <Thead
              position="sticky"
              top="0"
              bg="gray.100" // You can choose a different color if desired
              zIndex="1"
            >
              <Tr>
                <Th>View</Th>
                <Th>Edit</Th>
                <Th>ID</Th>
                <Th>Supplier Ref #</Th>
                <Th>Date</Th>
                <Th>Total Cases</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr key={newRow.id}>
                <Td>
                  <IconButton variant="outline" colorScheme="customRed" size="sm" icon={<FaEye />} aria-label="View" onClick={() => handleOpenView(newRow)} />
                </Td>
                <Td>
                  <IconButton variant="outline" colorScheme="green" icon={<FaEdit />} size="sm" aria-label="Edit" onClick={() => handleOpenEdit(newRow)} isDisabled={!newRow.supplierRef || !newRow.totalCases || isNaN(newRow.totalCases) || newRow.totalCases <= 0} />
                </Td>
                <Td>{newRow.id}</Td>
                <Td>
                  <Input borderRadius="md" size="sm" p={1} bg="white" value={newRow.supplierRef} onChange={(e) => setNewRow({ ...newRow, supplierRef: e.target.value })} />
                </Td>
                <Td>
                  <DatePicker
                    placeholderText="Select Order Date"
                    selected={newRow.date ? new Date(newRow.date) : null}
                    onChange={(date) =>
                      setNewRow({
                        ...newRow,
                        date: date
                          ? date.toLocaleDateString("en-CA", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })
                          : "",
                      })
                    }
                    dateFormat="yyyy/MM/dd"
                    customInput={<Input width="200px" />}
                  />
                </Td>
                <Td>
                  <Input borderRadius="md" size="sm" p={1} bg="white" value={newRow.totalCases} onChange={(e) => setNewRow({ ...newRow, totalCases: e.target.value })} />
                </Td>
              </Tr>
            </Tbody>
          </Table>
        )}

        <Box minHeight="540px" overflowY="auto">
          <Table variant="striped" size="sm" colorScheme="gray" borderColor="white.200" borderWidth="2px" borderStyle="solid" width="100%">
            <Thead>
              <Tr>
                <Th>View</Th>
                <Th>Supplier Ref #</Th>
                <Th>Supplier</Th>
                <Th>Date Received</Th>
                <Th>Total Cases</Th>
                <Th>Date Created</Th>
              </Tr>
            </Thead>
            <Tbody>
              {tableData.map((item, index) => (
                <Tr key={item.supplier_reference_number}>
                  <Td>
                    <IconButton variant="outline" colorScheme="customRed" size="sm" icon={<FaEye />} aria-label="View" onClick={() => handleOpenView(item, index)} />
                  </Td>
                  <Td>{item.supplier_reference_number}</Td>
                  <Td>{item.suppliercode}</Td>
                  <Td>{item.custom_date}</Td>
                  <Td>{item.quantity}</Td>
                  <Td>{item.clean_created_date}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Flex justifyContent="space-between" mt={4}>
            <Button
              onClick={() => handlePageChange(page - 1)}
              isDisabled={page === 0} // Disable Previous button if on the first page
            >
              Previous
            </Button>
            <Button
              onClick={() => handlePageChange(page + 1)}
              isDisabled={(page + 1) * limit >= totalCount} // Disable Next button if there are no more invoices
            >
              Next
            </Button>
          </Flex>
        </Box>
      </Box>

      <Flex justifyContent="space-between" mt={4}>
        <Button
          onClick={() => handlePageChange(page - 1)}
          isDisabled={page === 0} // Disable Previous button if on the first page
        >
          Previous
        </Button>
        <Button
          onClick={() => handlePageChange(page + 1)}
          isDisabled={(page + 1) * limit >= totalCount} // Disable Next button if there are no more invoices
        >
          Next
        </Button>
      </Flex>

      {selectedItems && (
        <ViewItem
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
            setSelectedItems(null);
          }}
          items={selectedItems}
        />
      )}
    </>
  );
}

export default GRV;
