import React, { useEffect } from "react";
import { formatDateTime, formatDate } from "../../../../../components/GenericFunctions";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Table, Thead, Tbody, Tr, Th, Td, Box } from "@chakra-ui/react";

const ViewItem = ({ isOpen, onClose, items }) => {
  console.log(items);
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="7xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Item Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4}>
            <Table
              variant="simple"
              size="xl"
              sx={{
                borderCollapse: "collapse",
                "th, td": {
                  padding: "1px",
                  fontSize: "12px",
                },
              }}
            >
              <Thead>
                <Tr>
                  <Th>Article Code</Th>
                  <Th>Article Description</Th>
                  <Th>Supplier Code</Th>
                  <Th>Supplier Reference Number</Th>
                  <Th>Warehouse Code</Th>
                  <Th>Article Cost</Th>
                  <Th>Article SOH</Th>
                  <Th>Article BB Date</Th>
                  <Th>Article Comment</Th>
                  <Th>Created Date</Th>
                  <Th>Edited Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {items.map((item, index) => (
                  <Tr key={index}>
                    <Td>{item.articlecode}</Td>
                    <Td>{item.article_desc}</Td>
                    <Td>{item.suppliercode}</Td>
                    <Td>{item.supplier_reference_number}</Td>
                    <Td>{item.warehousecode}</Td>
                    <Td>{parseFloat(item.article_cost).toFixed(2)}</Td>
                    <Td>{item.article_soh}</Td>
                    <Td>{item.article_bb_date}</Td>
                    <Td>{item.article_comment}</Td>
                    <Td>{formatDate(item.clean_created_date)}</Td>
                    <Td>{formatDateTime(item.edited_date)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ViewItem;
