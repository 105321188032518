import React, { useState, useEffect } from "react";
import { Box, Button, Spinner, Table, Thead, Tbody, Tr, Td, Th, IconButton, Input, Select, useToast, Flex } from "@chakra-ui/react";
import { FaEye, FaEdit } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ViewItem from "./ViewItem";
import Capture from "./Capture";
import Filters from "./Filters"; // Reuse Filters from GRV
import axios from "axios";

function StockTransfer({ organisation, warehouse, supplier, distributor }) {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [filters, setFilters] = useState([]);
  const [fromDistributorCodes, setFromDistributorCodes] = useState([]);
  const [warehousecodes, setWarehousecodes] = useState([]);
  const [page, setPage] = useState(0);
  const [limit] = useState(50);
  const [totalCount, setTotalCount] = useState(0);

  const toast = useToast();

  const handleFilterSubmit = (selectedFilters) => {
    setFilters(selectedFilters);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage * limit < totalCount) {
      setPage(newPage);
      fetchStockTransfers();
    }
  };

  const fetchStockTransfers = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/stock/get-stock-transfers`, { organisation, warehouse, supplier, distributor, filters, limit, offset: page * limit });

      setTableData(response.data.data.transfers);

      setWarehousecodes(response.data.data.warehousecodes);
      setTotalCount(response.data.data.totalCount);
      setLoading(false);
    } catch (error) {
      toast({
        title: "Failed to load stock transfers",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStockTransfers();
  }, [warehouse, supplier, filters]);

  const handleWarehouseChange = (index, value) => {
    setTableData((prevData) => prevData.map((item, i) => (i === index ? { ...item, new_warehousecode: value } : item)));
  };

  const handleTransferedSohChange = (index, value) => {
    setTableData((prevData) => prevData.map((item, i) => (i === index ? { ...item, transfered_article_soh: Math.min(parseInt(value, 10) || 0, item.article_soh) } : item)));
  };
  const handleSupplierRefChange = (index, value) => {
    setTableData((prevData) => prevData.map((item, i) => (i === index ? { ...item, new_supplier_reference_number: value } : item)));
  };
  const handleOpenView = async (item, index) => {
    setLoading(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/stock/view-stock-transfer`, { organisationcode: organisation, warehousecode: item.warehousecode, articlecode: item.articlecode });
      const stock_metadata = res.data.stock_metadata;
      console.log(stock_metadata);

      setSelectedItems(stock_metadata);
      setIsPopupOpen(true);

      setLoading(false);
    } catch (error) {
      console.log({ error });
      toast({
        title: "Failed to load stock transfer",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
    }

    // setSelectedItem(item);
    // setSelectedIndex(index);
    // setIsPopupOpen(true);
    //articlecode,warehousecode
  };

  const handleCloseView = () => {
    setIsPopupOpen(false);
    setSelectedItem(null);
    setSelectedIndex(null);
  };

  const handleOpenEdit = (item, index) => {
    setSelectedItem(item);
    setSelectedIndex(index);
    setEditMode(true);
  };

  const handleEditComplete = async (metadata) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/stock/ins-stock-transfer`, {
        stock_metadata: metadata,
        stock: selectedItem,
        organisation,
        warehouse,
        supplier,
        userid: localStorage.getItem("userid"),
      });
      fetchStockTransfers();
      setEditMode(false);
      setSelectedItem(null);
      setSelectedIndex(null);
    } catch (error) {
      toast({
        title: "Failed to update stock transfer",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (editMode) {
    return (
      <Capture
        item={selectedItem}
        organisation={organisation}
        warehouse={warehouse}
        supplier={supplier}
        index={selectedIndex}
        onSubmit={handleEditComplete}
        onCancel={() => {
          setEditMode(false);
          setSelectedItem(null);
          setSelectedIndex(null);
        }}
      />
    );
  }

  if (loading) return <Spinner />;

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={4} gap={4} position="absolute" right="15px" top="75px">
        <Filters onFilterSubmit={handleFilterSubmit} fromDistributorCodes={fromDistributorCodes} warehousecodes={warehousecodes} />
      </Box>

      <Box overflowY="auto" maxHeight="500px" p={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid" width="100%">
          <Thead
            position="sticky"
            top="0"
            bg="gray.100" // You can choose a different color if desired
            zIndex="1"
          >
            <Tr>
              <Th>View</Th>
              <Th>Edit</Th>
              <Th>Supplier Reference Number</Th>
              <Th>Article Code</Th>
              <Th>Article Description</Th>
              <Th>SOH</Th>
              <Th>Transfered SOH</Th>
              <Th>FROM WH</Th>
              <Th>To WH</Th>
            </Tr>
          </Thead>
          <Tbody>
            {tableData.map((item, index) => (
              <Tr key={item.id}>
                <Td>
                  <IconButton variant="outline" colorScheme="customRed" size="sm" icon={<FaEye />} aria-label="View" onClick={() => handleOpenView(item, index)} />
                </Td>
                <Td>
                  <IconButton variant="outline" colorScheme="green" size="sm" icon={<FaEdit />} aria-label="Edit" onClick={() => handleOpenEdit(item, index)} />
                </Td>
                <Td>
                  {" "}
                  <Input borderRadius="md" size="sm" p={1} bg="white" value={item.new_supplier_reference_number} onChange={(e) => handleSupplierRefChange(index, e.target.value)} />
                </Td>
                <Td>{item.articlecode}</Td>
                <Td>{item.article_desc}</Td>
                <Td>{item.article_soh}</Td>
                <Td>
                  <Input type="number" min="0" max={item.article_soh} value={item.transfered_article_soh || ""} onChange={(e) => handleTransferedSohChange(index, e.target.value)} />
                </Td>
                <Td>{item.warehousecode}</Td>
                <Td>
                  <Select placeholder="Select warehouse" value={item.new_warehousecode || ""} onChange={(e) => handleWarehouseChange(index, e.target.value)}>
                    {warehousecodes.map((code) => (
                      <option key={code} value={code}>
                        {code}
                      </option>
                    ))}
                  </Select>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Flex justifyContent="space-between" mt={4}>
        <Button
          onClick={() => handlePageChange(page - 1)}
          isDisabled={page === 0} // Disable Previous button if on the first page
        >
          Previous
        </Button>
        <Button
          onClick={() => handlePageChange(page + 1)}
          isDisabled={(page + 1) * limit >= totalCount} // Disable Next button if there are no more invoices
        >
          Next
        </Button>
      </Flex>

      {selectedItems && (
        <ViewItem
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
            setSelectedItems(null);
          }}
          items={selectedItems}
        />
      )}
    </>
  );
}

export default StockTransfer;
